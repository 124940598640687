import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../partial/Loading";

const Booked = () => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

    }, [])

    return (

        <div className="tab-pane fade " id="booking" role="tabpanel"
             aria-labelledby="booking-tab">

            <div className="mb-5">
                <h1>Gebuchte Reisen</h1>

                <Loading isLoading={isLoading}/>

            </div>
        </div>
    )
}

export default Booked;