import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import TripCard from "./partial/TripCard";
import InfiniteScroll from "react-infinite-scroller";
import Fetching from "./fetching";
import axiosConfig from "./utils/axiosConfig";
import {allTripTypes, fetchDestinations, mapTripType} from "./utils/utils";
import {BrowserView, MobileView} from "react-device-detect";

const Finder = () => {
    const [searchParams] = useSearchParams();
    const [search, setSearch] = useState(searchParams.get("s") ?? "");
    const [type, setType] = useState(searchParams.get("reiseart") ?? "");
    const [dest, setDest] = useState(searchParams.get("reiseziel") ?? "");

    const navigate = useNavigate();

    const [destinations, setDestinations] = useState([]);
    const [trips, setTrips] = useState([]);

    const [nextPage, setNextPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [fetching, setFetching] = useState(false);

    // Function to fetchDestinations using Axios
    const fetchTrips = useCallback(async () => {
        if (fetching) return;

        setFetching(true);
        try {
            const cType = mapTripType(type);
            const response = await axiosConfig.get(`/agency/${process.env.REACT_APP_AGENCY_NO}/trips?type=${cType}&dest=${dest}&search=${search}&page=${nextPage}`);
            setTrips([...trips, ...response.data.data]);

            setNextPage(response.data.current_page + 1)
            setLastPage(response.data.last_page)
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setFetching(false);
        }

    }, [nextPage, lastPage, fetching]);

    const hasMoreItems = nextPage <= lastPage;

    const reloadP = (search, type, dest) => {
        setSearch(search)
        setType(type)
        setDest(dest)
        navigate(`/reisefinder?s=${search}&tour_search=1&reiseart=${type}&reiseziel=${dest}`);
        window.location.reload();
    }

    // Call fetchData on component mount
    useEffect(() => {
        document.title = `${document.title} - Reisefinder`;
        fetchDestinations().then((data) => {
            setDestinations(data);
        });
    }, []);
    return (
        <div className="archive travel_tour travel_tour-page">
            <div className="site wrapper-content">
                <div className="top_site_main" style={{backgroundColor: '#fff'}}>
                </div>
                <section className="content-area">
                    <div className="container-fluid">
                        <BrowserView>
                            <div className="row">
                                <div className="widget-area col-md-3 col-sm-12 position-relative">
                                    <div className="position-sticky top-0">
                                        <div className="row mb-3">
                                            <div className="col-sm ">
                                                {search &&
                                                    <span className="badge rounded-pill p-2">
                                                    {search}
                                                        <button className="button-icon" onClick={() => {
                                                            reloadP("", type, dest)
                                                        }}><i
                                                            className="fa fa-close ps-5"></i></button>
                                                </span>
                                                }
                                                {type &&
                                                    <span className="badge rounded-pill p-2">
                                                    {type}
                                                        <button className="button-icon" onClick={() => {
                                                            reloadP(search, "", dest)
                                                        }}><i
                                                            className="fa fa-close ps-5"></i></button>
                                                </span>

                                                }
                                                {dest &&
                                                    <span className="badge rounded-pill p-2">
                                                    {dest}
                                                        <button className="button-icon" onClick={() => {
                                                            reloadP(search, type, "")
                                                        }}><i
                                                            className="fa fa-close ps-5"></i></button>
                                                </span>
                                                }
                                            </div>
                                        </div>
                                        <div className="search_tour">
                                            <div className="form-block block-after-indent">
                                                <h3 className="form-block_title">Reisefilter</h3>
                                                <div className="form-block__description">Finden Sie Ihre Traumreise
                                                    jetzt!
                                                </div>
                                                <form method="get" action="#">
                                                    <input type="hidden" name="tour_search" value="1"/>
                                                    <input type="text" className="form-control" id="s" name="s"
                                                           placeholder="Suchbegriff eingeben..."
                                                           value={search}
                                                           onChange={(e) => setSearch(e.target.value)}/>
                                                    <select className="form-select" name="reiseart"
                                                            value={type}
                                                            onChange={(e) => setType(e.target.value)}>
                                                        <option value="">Reiseart</option>
                                                        {allTripTypes.map((post, i) => (
                                                            <option value={post.title} key={i}>{post.title}</option>
                                                        ))}
                                                    </select>
                                                    <select className="form-select" name="reiseziel"
                                                            value={dest}
                                                            onChange={(e) => setDest(e.target.value)}>
                                                        <option value=""> Reiseziel</option>
                                                        {destinations?.map((post, x) => (
                                                            <option value={post.Kontinent_2012}
                                                                    key={x}>{post.Kontinent_2012}</option>
                                                        ))}
                                                    </select>
                                                    <button type="submit">Suchen</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="site-main col-md-9 col-sm-12">
                                    <InfiniteScroll
                                        pageStart={nextPage}
                                        loadMore={fetchTrips}
                                        hasMore={hasMoreItems}
                                        loader={<Fetching isFetching={true}></Fetching>}
                                    >
                                        <ul className="tours products wrapper-tours-slider">
                                            {trips.length > 0 ? (trips.map((post) => (
                                                <TripCard trip={post} cnt={3}></TripCard>
                                            ))) : (
                                                <h1 className="text-center">Keine Reisen gefunden</h1>
                                            )}
                                        </ul>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </BrowserView>
                        <MobileView>
                            <div className="row">
                                <div className="site-main col-md-12 col-sm-12">
                                    <div className="row mb-3">
                                        <div className="col-sm ">
                                            {search &&
                                                <span className="badge rounded-pill p-2">
                                                    {search}
                                                    <button className="button-icon" onClick={() => {
                                                        reloadP("", type, dest)
                                                    }}><i
                                                        className="fa fa-close ps-5"></i></button>
                                                </span>
                                            }
                                            {type &&
                                                <span className="badge rounded-pill p-2">
                                                    {type}
                                                    <button className="button-icon" onClick={() => {
                                                        reloadP(search, "", dest)
                                                    }}><i
                                                        className="fa fa-close ps-5"></i></button>
                                                </span>

                                            }
                                            {dest &&
                                                <span className="badge rounded-pill p-2">
                                                    {dest}
                                                    <button className="button-icon" onClick={() => {
                                                        reloadP(search, type, "")
                                                    }}><i
                                                        className="fa fa-close ps-5"></i></button>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                    <InfiniteScroll
                                        pageStart={nextPage}
                                        loadMore={fetchTrips}
                                        hasMore={hasMoreItems}
                                        loader={<Fetching isFetching={true}></Fetching>}
                                    >
                                        <ul className="tours products wrapper-tours-slider">
                                            {trips.length > 0 ? (trips.map((post) => (
                                                <TripCard trip={post} cnt={3}></TripCard>
                                            ))) : (
                                                <h1 className="text-center">Keine Reisen gefunden</h1>
                                            )}
                                        </ul>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </MobileView>


                    </div>
                </section>
            </div>
        </div>
    )
}

export default Finder;