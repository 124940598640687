import React from 'react';
import {nl2br} from "../../utils/utils";

const DraftTripPreviewTab = ({trip}) => {

    const tripFacts = [];
    for (let i = 1; i <= 11; i++) {
        const number = i < 10 ? '0' + i : i;
        tripFacts.push(number);
    }

    return (
        <div id="pane-tripPreviewTab" className="tab-pane active single-tour-tabs-panel panel entry-content wc-tab" role="tabpanel" aria-labelledby="tab-tripPreviewTab">
            <div className="row">
                <div className="col-md-6">
                    {trip.Fact_TOP &&
                        <div className="mb-3">
                            <p className="p-wrap">{nl2br(trip.Fact_TOP)}</p>
                        </div>
                    }
                </div>
            </div>

            <div className="mb-3">
                <ul className="list-group borderless">
                    <div className="borderless">
                        {tripFacts.map((fact) => (
                            trip["Fact_" + fact] &&
                            <li className="list-group-item borderless">
                                <i className="fa fa-check-circle"></i> {trip["Fact_" + fact]}
                            </li>
                        ))}
                    </div>
                </ul>
            </div>

            <div className="mb-3">
                <p className="p-wrap">
                    {trip.Einleitung}
                </p>
            </div>

            <div className="mb-3">
                <img loading="lazy"
                     src={process.env.REACT_APP_IMG_PATH + trip.map.Bildname + '_low.jpg'}
                     className="center-block" width="70%"/>
            </div>

            <div className="mb-3">
                <p className="mb-3">
                    <strong>Reiseverlauf: </strong>
                    {trip.Stationen}
                </p>
            </div>
        </div>
    )
}

export default DraftTripPreviewTab;