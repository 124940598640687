import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../context/AuthContext";
import axiosConfig from "../utils/axiosConfig";
import {formatCurrency, localizeDate, notify} from "../utils/utils";
import Loading from "../partial/Loading"; // Optional Theme applied to the Data Grid
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import "datatables.net-dt/css/dataTables.dataTables.css";

DataTable.use(DT);

const Reports = ({agency}) => {
    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const currentYear = new Date().getFullYear();
    const [from, setFrom] = useState(`${currentYear}-01-01`);
    const [to, setTo] = useState(`${currentYear}-12-31`);
    const [agencyNo, setAgencyNo] = useState(auth.user.Kundennummer);
    const [isEmpty, setIsEmpty] = useState(false);
    const [report, setReport] = useState([]);


    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([
        {
            data: 'Abflugtermin', render: function (data) {
                return localizeDate(data);
            }
        },
        {data: 'VorgangNummer'},
        {data: 'AgenturName'},
        {data: 'Reisetitel'},
        {
            data: 'AgenturProvision', render: function (data) {
                return formatCurrency(data);
            }
        },
        {
            data: 'AgenturUmsatz', render: function (data) {
                return formatCurrency(data);
            }
        },
    ]);

    const load = async () => {
        return await axiosConfig.get(`/protected/agency/${agencyNo}/provisions/${from}/${to}`, {
            "headers": {
                "Authorization": `Bearer ${auth.token}`,
                "Content-Type": "application/json"
            },
        });
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        if (agencyNo === 0) {
            notify("Bitte ein Agentur auswählen");
            return
        }

        setIsLoading(true);
        setIsEmpty(false);

        load().then((result) => {
            setIsLoading(false);
            if (result.data.transactions.length === 0) {
                setIsEmpty(true);
                setReport([])
                setTableData([])
            } else {
                setReport(result.data)
                setTableData(result.data.transactions)
            }
        }).catch((e) => {
            notify(e.message)
            setIsLoading(false);
        })
    };

    useEffect(() => {
    }, [])

    return (
        <div className="tab-pane fade " id="report" role="tabpanel"
             aria-labelledby="report-tab">

            <div className="mb-5">
                <h1>Report Forecast Provisionen</h1>
                <p><i className="fa fa-info-circle"></i> Das Zusammenstellen der Daten ist
                    sehr
                    rechenintensiv. Bitte haben Sie etwas Geduld, bis Ihnen der Report
                    angezeigt
                    wird.</p>
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="date" className="form-control"
                                       name="from"
                                       value={from}
                                       onChange={(e) => setFrom(e.target.value)}
                                       id="from" placeholder="Zeitraum von"

                                />
                                <label htmlFor="from" className="form-label">Zeitraum
                                    von:</label>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <input type="date" className="form-control"
                                       name="to"
                                       value={to}
                                       onChange={(e) => setTo(e.target.value)}
                                       id="to" placeholder="Zeitraum bis"
                                />
                                <label htmlFor="to" className="form-label">Zeitraum
                                    bis</label>
                            </div>
                        </div>
                    </div>


                    <div className="form-floating mb-3">
                        <select className="form-select" aria-label="Default select example"
                                onChange={(e) => setAgencyNo(e.target.value)}>
                            <option
                                value={agency?.Agenturnummer}>{agency?.Name_1} {agency?.Name_2}</option>
                        </select>
                        <label htmlFor="email" className="form-label">Agentur
                            auswählen</label>

                    </div>
                    <button type="submit" className="btn btn-primary" disabled={isLoading}>
                        <i
                            className="fa fa-list"></i> Report
                        erstellen
                    </button>
                </form>
            </div>

            <Loading isLoading={isLoading}/>
            {report.transactions?.length > 0 && !isLoading &&
                <>
                    <div>
                        <p>
                            <span> Gesamtsumme im abgefragten Zeitraum: {localizeDate(report.from)} -{localizeDate(report.to)} </span>
                            <span> Provision: {formatCurrency(report.sumAgProv)} |
                                                     Umsatz: {formatCurrency(report.sumUmsatz)} </span>
                        </p>
                    </div>

                    <DataTable
                        options={
                            {
                                responsive: true,
                                select: true,

                            }
                        }
                        data={tableData} columns={columns} className="display">
                        <thead>
                        <tr>
                            <th>Abreise</th>
                            <th>Kontingent</th>
                            <th>Reise</th>
                            <th>Abreise</th>
                            <th>Provision</th>
                            <th>Umsatz</th>
                        </tr>
                        </thead>
                    </DataTable>
                </>
            }
            {
                isEmpty &&
                <div className="alert alert-danger" role="alert">
                    <p><i className="fa fa-exclamation-triangle"></i> keine daten vorhanden!
                    </p>
                </div>
            }
        </div>
    )
}

export default Reports;