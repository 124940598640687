import React, {useCallback, useEffect, useState} from 'react';
import {useParams, useSearchParams} from "react-router-dom";
import Fetching from "./fetching";
import TripCard from "./partial/TripCard";
import HeaderSubPage from "./partial/HeaderSubPage";
import axiosConfig from "./utils/axiosConfig";
import InfiniteScroll from "react-infinite-scroller";
import {allTripDestinations} from "./utils/utils";


const Destination = ({partner}) => {

    const params = useParams();
    const dest = params["dest"];
    const currentDest = allTripDestinations.find((d) => d.title === dest);

    const [searchParams] = useSearchParams();
    const currentCont = searchParams.get("kontinent");

    // State to store the fetched data
    const [trips, setTrips] = useState([]);

    const [nextPage, setNextPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [fetching, setFetching] = useState(false);

    const fetchTrips = useCallback(async () => {
        if (fetching) return;

        setFetching(true);
        try {
            const response = await axiosConfig.get(`/agency/${process.env.REACT_APP_AGENCY_NO}/trips?dest=${dest}&page=${nextPage}`);
            //console.log(response.data)
            setTrips([...trips, ...response.data.data]);

            setNextPage(response.data.current_page + 1)
            setLastPage(response.data.last_page)
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setFetching(false);
        }
    }, [nextPage, lastPage, fetching]);

    const hasMoreItems = nextPage <= lastPage;

    // Call fetchData on component mount
    useEffect(() => {
        document.title = `${partner.name.toUpperCase()} - ${dest}`;
    }, [partner, dest]);

    return (
        <div className="travel_tour">
            <div className="site wrapper-content">
                <HeaderSubPage title={dest} subtitle={'REISEZIELE'} bgImage={currentDest.bgImage}></HeaderSubPage>
                <section className="content-area">
                    <div className="container">
                        <div className="row">
                            <div className="site-main col-sm-6">
                                <div className="ce-bodytext">
                                    <h1 className="mb-5"> Unsere aktuellen Reisen in {dest} </h1>
                                    <p className="mb-5"></p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="site-main col-sm-12">
                                <InfiniteScroll
                                    loadMore={fetchTrips}
                                    hasMore={hasMoreItems}
                                    loader={<Fetching isFetching={fetching}></Fetching>}
                                >
                                    <ul className="tours products wrapper-tours-slider equal">
                                        {trips.map((post, index) => (
                                            <TripCard trip={post} cnt={4} key={index}></TripCard>
                                        ))}
                                    </ul>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Destination;