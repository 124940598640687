import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useAuth} from "../../context/AuthContext";
import {fetchContingentByAgency, localizeDate} from "../utils/utils";
import Loading from "../partial/Loading"; // Optional Theme applied to the Data Grid
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import 'datatables.net-select-dt';
import 'datatables.net-responsive-dt';
import "datatables.net-dt/css/dataTables.dataTables.css";

DataTable.use(DT);

const Contingents = () => {
    const auth = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [contingents, setContingents] = useState([]);
    const [columns, setColumns] = useState([
        {
            data: 'Abflugtermin', render: function (data) {
                return localizeDate(data);
            }
        },
        {data: 'Destination'},
        {data: 'Reisetitel'},
        {
            data: 'Reise_ID', render: function (data, type, row, meta) {
                return `<a target="_blank" href="/reise/${row.Reisetitel}?id=${row.Reise_ID}">Reise anschauen</a>`;
            }
        },
        {
            data: 'id', render: function (data, type, row, meta) {
                if (row.rest <= 0) {
                    return "Aktuell ausgebucht";
                } else {
                    return `<a target="_blank" href="/reisebuchung/${row.Reisetitel}?id=${row.Reise_ID}&tNo=${row.VORGANGNR}">Jetz Buchen</a>`;
                }
            }
        },
    ]);


    useEffect(() => {
        setIsLoading(true);
        fetchContingentByAgency(auth.user.Kundennummer, auth.token).then((response) => {
            setContingents(response)
            setIsLoading(false)
        });
    }, [])

    return (
        <div className="tab-pane fade " id="contingent" role="tabpanel"
             aria-labelledby="contingent-tab">

            <div className="mb-5">
                <h1>Kontingente</h1>
                <p>
                    <i className="fa fa-info-circle"></i> Hier können Sie Ihre Reisegäste auf Ihre Kontingente
                    einbuchen. Klicken Sie beim gewünschten
                    Kontingent auf &quot;<b>Buchen</b>&quot;
                </p>
            </div>

            <Loading isLoading={isLoading}/>

            <DataTable
                options={
                    {
                        processing: true,
                        responsive: true,
                        select: true,

                    }
                }
                data={contingents} columns={columns} className="display">
                <thead>
                <tr>
                    <th>Abreise</th>
                    <th>Reiseziel</th>
                    <th>Title</th>
                    <th>Reisevorschau</th>
                    <th>Buchen</th>
                </tr>
                </thead>
            </DataTable>

        </div>
    )
}

export default Contingents;